// src/components/Navbar.js

import React, { useState } from "react";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center"> {/* Ensure items-center here */}
          <div className="flex">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <a href="/" className="text-xl font-bold text-gray-900">
                AI Solutions
              </a>
            </div>

            {/* Links */}
            <div className="hidden md:flex space-x-8 ml-10 items-center h-full"> {/* items-center here */}
              <a href="#" className="text-gray-900 hover:text-gray-700">
                Home
              </a>
              <a href="#" className="text-gray-900 hover:text-gray-700">
                About
              </a>

              {/* AI Solutions Dropdown */}
              <div className="relative">
                <button
                  className="flex items-center px-3 py-2 text-gray-900 hover:text-gray-700 focus:outline-none"
                  onClick={toggleDropdown}
                >
                  AI Solutions
                  <svg
                    className="w-5 h-5 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {/* Dropdown Content */}
                {dropdownOpen && (
                  <div className="absolute left-0 z-10 mt-2 w-72 bg-white border rounded-lg shadow-lg">
                    <div className="grid grid-cols-1 gap-4 p-4">
                      <div>
                        <h3 className="text-sm font-semibold text-gray-900">
                          AI Learning Solutions
                        </h3>
                        <a
                          href="#"
                          className="block mt-2 text-sm text-gray-500 hover:text-gray-700"
                        >
                          AI Flashcards
                        </a>
                        <a
                          href="/products/survey"
                          className="block mt-2 text-sm text-gray-500 hover:text-gray-700"
                        >
                          AI Survey Tool
                        </a>
                      </div>
                      <div>
                        <h3 className="text-sm font-semibold text-gray-900">
                          AI Data Analytics
                        </h3>
                        <a
                          href="#"
                          className="block mt-2 text-sm text-gray-500 hover:text-gray-700"
                        >
                          Data Visualization
                        </a>
                        <a
                          href="#"
                          className="block mt-2 text-sm text-gray-500 hover:text-gray-700"
                        >
                          Predictive Analytics
                        </a>
                      </div>
                      {/* More categories if needed */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Right side (if any) */}
          <div className="hidden md:flex items-center">
            <a href="#" className="text-gray-900 hover:text-gray-700">
              Sign In
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
