import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons'; // Import the globe icon

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isJapanese, setIsJapanese] = useState(i18n.language === 'ja');

  const handleLanguageToggle = () => {
    const newLanguage = isJapanese ? 'en' : 'ja';
    i18n.changeLanguage(newLanguage);
    setIsJapanese(!isJapanese);
  };

  return (
    <header className="bg-gray-800 text-white py-4">
      <div className="container mx-auto flex items-center justify-between">
        <h1 className="text-3xl font-bold">TechExtend.ai</h1>
        <nav>
          <ul className="flex items-center space-x-4">
            <li className="flex items-center">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `px-4 py-2 rounded-full flex items-center ${isActive ? 'bg-blue-500 text-white' : 'text-gray-300 hover:bg-gray-700'}`
                }
              >
                {t('home')}
              </NavLink>
            </li>
            <li className="flex items-center">
              <NavLink
                to="/solutions"
                className={({ isActive }) =>
                  `px-4 py-2 rounded-full flex items-center ${isActive ? 'bg-blue-500 text-white' : 'text-gray-300 hover:bg-gray-700'}`
                }
              >
                {t('solutions')}
              </NavLink>
            </li>
            <li className="flex items-center">
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `px-4 py-2 rounded-full flex items-center ${isActive ? 'bg-blue-500 text-white' : 'text-gray-300 hover:bg-gray-700'}`
                }
              >
                {t('about')}
              </NavLink>
            </li>
            <li className="flex items-center">
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `px-4 py-2 rounded-full flex items-center ${isActive ? 'bg-blue-500 text-white' : 'text-gray-300 hover:bg-gray-700'}`
                }
              >
                {t('contact')}
              </NavLink>
            </li>
            <li className="flex items-center space-x-2">
            <button
            onClick={handleLanguageToggle}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
            >
            <FontAwesomeIcon icon={faGlobe} className="text-xl mr-2" /> {/* Margin-right on the icon */}
            {isJapanese ? 'EN' : 'JA'}
            </button>
            </li>

          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
