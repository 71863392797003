import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import i18n configuration
import './index.css'; // or './tailwind.css'
import Header from './Header'; // Import the Header component
import Footer from './Footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Button from './Button';
import Navbar from './Navbar';
import SurveyProductHome from './pages/survey/Home';
import ParticlesComponent from './ParticlesComponent';
import ParticlesBackground from './ParticlesBackground';

// Page Components
import Home from './pages/Home';
import Solutions from './pages/Solutions';
import About from './pages/About';
import Contact from './pages/Contact';
import LoadingPage from './pages/LoadingPage';

function App() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
    <Router>
      <div className="App min-h-screen bg-gray-100 text-gray-900">
      {/* <ParticlesBackground /> */}


        <Header onLanguageChange={handleLanguageChange} />
        <Navbar /> {/* Add the Navbar component here */}
        {/* <ParticlesComponent /> */}
        
        <Routes>

        {/* <Route path="/loadingpage" element={<LoadingPage />} /> */}

          <Route path="/" element={<Home />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/products/survey" element={<SurveyProductHome />} />

        </Routes>
        <Footer />


      </div>
    </Router>
    </div>
  );
}


export default App;
