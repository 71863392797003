import React from 'react';

const Home = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <section className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl font-extrabold text-gray-900">Grow better with AI Solutions</h1>
          <p className="mt-4 text-lg text-gray-600">
            Seamlessly connect your data, teams, and customers on one AI-powered platform that grows with your business.
          </p>
          <div className="mt-8">
            <a
              href="/demo"
              className="px-5 py-3 bg-blue-600 text-white font-medium rounded-md shadow hover:bg-blue-700"
            >
              Get a demo of our premium software
            </a>
            <a
              href="/signup"
              className="ml-4 px-5 py-3 bg-gray-600 text-white font-medium rounded-md shadow hover:bg-gray-700"
            >
              Get started free with our AI tools
            </a>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-gray-900 text-center">How AI Solutions Work</h2>
          <p className="text-lg text-gray-600 text-center mt-4">
            AI-powered tools to help you generate leads, automate processes, and improve customer experiences.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
            {/* Feature 1 */}
            <div className="bg-white p-6 shadow rounded-lg">
              <h3 className="text-xl font-bold text-gray-900">AI Flashcards</h3>
              <p className="mt-4 text-gray-600">
                Leverage AI to generate study materials based on your course content.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="bg-white p-6 shadow rounded-lg">
              <h3 className="text-xl font-bold text-gray-900">AI Chatbots</h3>
              <p className="mt-4 text-gray-600">
                Automate customer support with AI-powered chatbots that help improve your customer service.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="bg-white p-6 shadow rounded-lg">
              <h3 className="text-xl font-bold text-gray-900">AI Data Analytics</h3>
              <p className="mt-4 text-gray-600">
                Get predictive analytics and deep insights from your data with AI-powered analytics tools.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Customer Logos Section */}
      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl font-bold text-gray-900">Trusted by 10,000+ customers</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-8 mt-8">
            {/* Customer Logos (use logos as images) */}
            <img src="/logos/ebay.png" alt="Ebay logo" className="w-32 mx-auto" />
            <img src="/logos/doordash.png" alt="Doordash logo" className="w-32 mx-auto" />
            <img src="/logos/reddit.png" alt="Reddit logo" className="w-32 mx-auto" />
            {/* Add more logos as needed */}
          </div>
        </div>
      </section>

      {/* Product Offerings Section */}
      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-gray-900 text-center">Our AI Solutions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
            {/* AI Solution 1 */}
            <div className="bg-white p-6 shadow rounded-lg">
              <h3 className="text-xl font-bold text-gray-900">AI Flashcards</h3>
              <p className="mt-4 text-gray-600">
                Automated flashcards that help you study smarter and retain information more effectively.
              </p>
            </div>

            {/* AI Solution 2 */}
            <div className="bg-white p-6 shadow rounded-lg">
              <h3 className="text-xl font-bold text-gray-900">AI Survey Tool</h3>
              <p className="mt-4 text-gray-600">
                Gather valuable customer feedback using AI to generate better surveys.
              </p>
            </div>

            {/* AI Solution 3 */}
            <div className="bg-white p-6 shadow rounded-lg">
              <h3 className="text-xl font-bold text-gray-900">AI Chatbots</h3>
              <p className="mt-4 text-gray-600">
                Provide 24/7 customer support with AI chatbots that understand customer needs.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 bg-white text-center">
        <h2 className="text-3xl font-bold text-gray-900">Ready to get started?</h2>
        <p className="mt-4 text-lg text-gray-600">
          Get a demo of our premium software, or get started with our free AI tools.
        </p>
        <div className="mt-8">
          <a
            href="/demo"
            className="px-5 py-3 bg-blue-600 text-white font-medium rounded-md shadow hover:bg-blue-700"
          >
            Get a demo
          </a>
          <a
            href="/signup"
            className="ml-4 px-5 py-3 bg-gray-600 text-white font-medium rounded-md shadow hover:bg-gray-700"
          >
            Get started free
          </a>
        </div>
      </section>
    </div>
  );
};

export default Home;
