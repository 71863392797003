import React from 'react';
import { Link } from 'react-router-dom';

const SurveyProductHome = () => {
  return (
    <div className="min-h-screen flex flex-col">

      <main className="flex-grow bg-gray-100 text-center">
        {/* Hero Section */}
        <section className="bg-blue-500 text-white py-16 px-8">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2">
              <h1 className="text-4xl md:text-[3rem] font-bold mb-6 leading-relaxed md:leading-normal">
                アンケート作成をAIにお任せ
                </h1>     
         <p className="text-xl mb-10">AIの力でアンケートを作成・管理しましょう。Google SurveyやCSVなどの一般的な形式に変換できます。</p>
              {/* <ul className="text-xl mb-10 list-disc list-inside">
              <li>AIの力でアンケートを作成・管理しましょう。</li>
              <li>Google SurveyやCSVなどの一般的な形式に変換できます。</li>
            </ul> */}
                <div className="flex justify-center space-x-4">
                  <Link to="/login">
                  <button className="px-6 py-3 bg-white text-blue-500 rounded-full hover:bg-gray-200" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
              ログインして<br />作成する
            </button>

                  </Link>
                  <Link to="/signup">
                    <button className="px-6 py-3 bg-green-500 text-white rounded-full hover:bg-green-700">会員登録して<br />作成する</button>
                  </Link>
                </div>
              </div>
              <div className="md:ml-8  md:w-1/2 flex justify-center md:justify-end">
              <img src="/images/alliconsv2.png" alt="App demonstration" className="w-full max-w-4xl mx-auto " />
            </div>
            </div>
          </div>
        </section>

      {/* Features Section */}
      <section className="py-16 px-8">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8">アプリの仕様</h2>
          <div className="grid gap-8 grid-cols-1 md:grid-cols-3">
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <img src="/images/gears.png" alt="Feature 1" className="w-32 h-32 mx-auto mb-4 object-cover" />
              <h3 className="text-xl font-bold mb-2">アンケートトピックをAIに依頼できる</h3>
              <p className="text-gray-700">ユーザーは人工知能を使ってアンケートトピックを考案することができます。</p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <img src="/images/deploy.png" alt="Feature 2" className="w-32 h-32 mx-auto mb-4 object-cover" />
              <h3 className="text-xl font-bold mb-2">AIを使ったアンケートの簡単な展開</h3>
              <p className="text-gray-700">AIを使用することで、最小限の調整でアンケートを展開することができます。</p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <img src="/images/multigear.png" alt="Feature 3" className="w-32 h-32 mx-auto mb-4 object-cover" />
              <h3 className="text-xl font-bold mb-2">Googleフォームやスプレッドシートとの連携</h3>
              <p className="text-gray-700">Googleフォームやスプレッドシートなどと連携し、任意の方法で簡単に展開できます。</p>
            </div>
          </div>
        </div>
      </section>

        
{/* Why Choose Us Section */}
<section className="bg-gray-200 py-16 px-8">
  <div className="container mx-auto">
    <h2 className="text-3xl font-bold mb-8">TechExtendのアンケート生成AIを選ぶ理由</h2>
    {/* <p className="text-gray-700 mb-4">当社のAI駆動の調査生成器は、調査の作成、配布、分析を簡単にします。MEJORAでできることは次のとおりです：</p> */}
    <div className="space-y-8">
      <div className="bg-white p-8 shadow-lg rounded-lg flex items-center">
        <div className="flex-shrink-0">
          <i className="fas fa-clock text-[64px] md:text-[96px]  mr-6"></i>
        </div>
        <div className="text-left">
          <h3 className="text-xl font-bold mb-2">アンケート作成時間の短縮</h3>
          <p className="text-lg text-gray-700">このサービスを利用することで、アンケートを生成するために費やす時間を大幅に削減できます。</p>
        </div>
      </div>
      <div className="bg-white p-8 shadow-lg rounded-lg flex items-center">
        <div className="flex-shrink-0">
          <i className="fas fa-chart-line text-[64px] md:text-[96px] mr-6"></i>
        </div>
        <div className="text-left">
          <h3 className="text-xl font-bold mb-2">効果的なAI生成質問</h3>
          <p className="text-lg text-gray-700">効果的なAI生成の質問は、必要な分析を向上させるのに役立ちます。</p>
        </div>
      </div>
      <div className="bg-white p-8 shadow-lg rounded-lg flex items-center">
        <div className="flex-shrink-0">
          <i className="fas fa-lightbulb text-[64px] md:text-[96px] mr-6"></i>
        </div>
        <div className="text-left">
          <h3 className="text-xl font-bold mb-2">優れた洞察の取得</h3>
          <p className="text-lg text-gray-700">AIを利用することで、より優れた洞察を得ることができます。</p>
        </div>
      </div>
    </div>
  </div>
</section>

      </main>
      <footer className="bg-white py-4">
        <div className="container mx-auto text-center text-gray-600">
          © 2024 TechExtend. All rights reserved. 
          {/* <Link to="/contact" className="hover:text-blue-500">Contact Us</Link> */}
        </div>
      </footer>
    </div>
  );
};


export default SurveyProductHome;
