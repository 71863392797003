import React from 'react';

const Contact = () => (
  <section className="p-12">
    <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
    <p className="text-lg">Contact information and form will be added here.</p>
  </section>
);

export default Contact;
