import React from 'react';
import { useTranslation } from 'react-i18next';
import Profile from './aboutpage/Profile'; 


const About = () => (
  <section className="p-12">
    {/* <Profile></Profile> */}
    <h2 className="text-3xl font-semibold mb-4">About Us</h2>
    <p className="text-lg">Information about our company and team will be added here.</p>
  </section>
);

export default About;
