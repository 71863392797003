import React from 'react';
import BarChart from '../BarChart';
const Solutions = () => (
  <section className="p-12">
    <h2 className="text-3xl font-semibold mb-4">Solutions</h2>
    <p className="text-lg">Details about the solutions we offer will be added here.</p>
    <BarChart />

  </section>
);

export default Solutions;