import React from 'react';

const Footer = () => (
  <footer className="bg-gray-800 text-white py-4 mt-8">
    <div className="container mx-auto text-center">
      <div className="flex justify-center space-x-4 mb-2">
        {/* Social Media Icons */}
        <a href="https://www.youtube.com/@TechExtendAI" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-youtube"></i>
        </a>
        <a href="https://www.linkedin.com/company/techextend-ai/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href="https://discord.gg/fhrMXDM2Rp" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-discord"></i>
        </a>
        <a href="https://x.com/techextendai" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/techextendai" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
      </div>
      <p>© 2024 TechExtend.ai. All rights reserved.</p>
      <p>contact: <a href="mailto:support@techextend.ai" className="text-blue-500">support@techextend.ai</a></p>
      {/* <div className="flex justify-center space-x-4 mt-2">
        <a href="https://github.com" className="text-blue-500">GitHub</a>
        <a href="/privacy" className="text-blue-500">Privacy</a>
        <a href="/terms" className="text-blue-500">Terms</a>
      </div> */}
    </div>
  </footer>
);

export default Footer;
